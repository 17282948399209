import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Privacybeleid": "nl/privacybeleid"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "se",
    href: "/se/integritetspolicy"
  },
  {
    hreflang: "da",
    href: "/da/privatlivspolitik"
  },
  {
    hreflang: "no",
    href: "/no/privacy-policy"
  },
  {
    hreflang: "nl",
    href: "/nl/privacybeleid"
  },
  {
    hreflang: "en",
    href: "/en/privacy-policy"
  },
  {
    hreflang: "de",
    href: "/de/datenschutz"
  },
  {
    hreflang: "x-default",
    href: "/en/privacy-policy"
  }
];

const PrivacyPolicy = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Privacybeleid"
        description="Details over mijn gegevensbeschermingsbeleid om uit te leggen hoe mijn diensten werken en hoe de bescherming van uw persoonlijke gegevens wordt gegarandeerd."
        lang="nl"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Gegevensbescherming</H>
        <p>Gegevensbeschermingsbeleid</p>
        <p>
          Hieronder wil ik uitleggen welke gegevens ik verzamel, verwerk en gebruik, wanneer en voor welk doel. Het doel is om
          u uit te leggen hoe mijn aangeboden diensten werken en hoe de bescherming van uw persoonlijke gegevens in dit opzicht
          wordt gegarandeerd.
        </p>
        <p>Ik verzamel, verwerk en gebruik alleen persoonlijke gegevens waar u toestemming voor heeft gegeven of waar de wet dit toestaat.</p>
        <p>
          Dit gegevensbeschermingsbeleid kan te allen tijde worden gedownload, opgeslagen en afgedrukt via de URL
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Naam en adres van de verwerkingsverantwoordelijke</H>
        <p>De verwerkingsverantwoordelijke in de zin van de wetgeving inzake gegevensbescherming is:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kopenhagen, Denemarken</p>
        <p>U kunt mij op elk moment per e-mail bereiken op legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Naam en adres van de functionaris voor gegevensbescherming</H>
        <p>Contactgegevens van de functionaris voor gegevensbescherming:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kopenhagen, Denemarken</p>
        <p>U kunt mij op elk moment per e-mail bereiken op legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Algemene punten over gegevensbescherming</H>
        <p>Omvang van de verwerking van persoonlijke gegevens</p>
        <p>
          Ik verwerk alleen persoonlijke gegevens van gebruikers wanneer dit nodig is voor het aanbieden van een operationele
          website en inhoud en diensten. In de regel worden persoonlijke gegevens van gebruikers alleen verwerkt na toestemming
          van de gebruiker. Een uitzondering geldt in gevallen waarin vooraf geen toestemming kan worden verkregen om feitelijke redenen en de verwerking van de gegevens is toegestaan door wettelijke voorschriften.
        </p>
        <H as="h2">Rechtsgrondslag voor de verwerking van persoonlijke gegevens</H>
        <p>
          Artikel 6 (1, a) van de EU Algemene Verordening Gegevensbescherming (AVG) dient als rechtsgrondslag wanneer ik toestemming van de betrokkene verkrijg voor de verwerking van persoonlijke gegevens.
        </p>
        <p>
          Artikel 6 (1, b) AVG dient als rechtsgrondslag wanneer de verwerking van persoonlijke gegevens noodzakelijk is voor het uitvoeren van een contract waarbij de betrokkene partij is. Dit geldt ook voor verwerking die noodzakelijk is voor het uitvoeren van precontractuele maatregelen.
        </p>
        <p>
          Artikel 6 (1, c) AVG dient als rechtsgrondslag wanneer de verwerking van persoonlijke gegevens noodzakelijk is voor het nakomen van een wettelijke verplichting waaraan mijn bedrijf onderhevig is.
        </p>
        <p>
          Wanneer de verwerking noodzakelijk is voor het waarborgen van een legitiem belang van mijn bedrijf of een derde, en de belangen, grondrechten en fundamentele vrijheden van de betrokkene niet zwaarder wegen dan het eerstgenoemde belang, dient artikel 6 (1, f) AVG als rechtsgrondslag voor de verwerking.
        </p>

        <H as="h2">Gegevenswissing en opslagduur</H>
        <p>
          De persoonlijke gegevens van de betrokkene worden gewist of geblokkeerd zodra het doel van opslag niet meer bestaat.
          Opslag kan ook plaatsvinden als dit is bedoeld door Europese of nationale wetgevers in regelgeving, wetten of andere
          vereisten krachtens EU-wetgeving waaraan de verwerkingsverantwoordelijke onderhevig is. Gegevens worden ook gewist als een opslagperiode voorgeschreven door de genoemde wetgeving is verstreken, tenzij er een vereiste is voor verdere opslag van de gegevens voor het aangaan of uitvoeren van een contract.
        </p>
        <p>Soorten verwerkte gegevens:</p>
        <p>– Contactgegevens (bijv. e-mail, naam).</p>
        <p>– Gebruiksgegevens (bijv. bezochte pagina's, interesse in inhoud, toegangstijden).</p>
        <p>– Meta/communicatiegegevens (bijv. apparaat informatie, IP-adressen).</p>
        <H as="h2">Categorieën van betrokkenen</H>
        <p>Bezoekers en gebruikers van het online aanbod, hierna samengevat als "gebruikers".</p>
        <H as="h2">Doel van de verwerking</H>
        <p>– Het aanbieden van het online aanbod, de functies en inhoud.</p>
        <p>– Beantwoorden van contactverzoeken en communicatie met gebruikers.</p>
        <p>– Beveiligingsmaatregelen.</p>
        <p>– Publieksmeting/marketing</p>
        <H as="h3">Gebruikte definities</H>
        <p>
          'Persoonlijke gegevens' betekent alle informatie met betrekking tot een geïdentificeerde of identificeerbare natuurlijke persoon ('betrokkene'); een identificeerbare natuurlijke persoon is iemand die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator (bijv. cookie) of een of meer factoren die specifiek zijn voor de fysieke, fysiologische, genetische, mentale, economische, culturele of sociale identiteit van die natuurlijke persoon.
        </p>
        <p>
          'Verwerking' betekent elke bewerking of reeks van bewerkingen die wordt uitgevoerd op persoonlijke gegevens of op verzamelingen van persoonlijke gegevens, al dan niet via geautomatiseerde middelen. De term is zeer breed en omvat praktisch elke omgang met gegevens.
        </p>
        <p>
          'Verwerkingsverantwoordelijke' verwijst naar de natuurlijke of rechtspersoon, overheidsinstantie, agentschap of andere instantie die, alleen of samen met anderen, het doel van en de middelen voor de verwerking van persoonlijke gegevens vaststelt.
        </p>
        <H as="h2">Relevante rechtsgrondslagen</H>
        <p>
          In overeenstemming met Artikel 13 AVG, informeer ik u over de rechtsgrondslagen van onze gegevensverwerking. Het volgende geldt indien de rechtsgrondslag niet wordt vermeld in het gegevensbeschermingsbeleid: De rechtsgrondslag voor het verkrijgen van toestemming is Artikel 6 (1, a) en Artikel 7 AVG, de rechtsgrondslag voor verwerking voor het uitvoeren van onze diensten en het uitvoeren van contractuele maatregelen, evenals het beantwoorden van vragen is Artikel 6 (1, b) AVG, de rechtsgrondslag voor verwerking voor het nakomen van onze wettelijke verplichtingen is Artikel 6 (1, c) AVG, en de rechtsgrondslag voor verwerking voor het waarborgen van onze legitieme belangen is Artikel 6 (1, f) AVG.
        </p>
        <H as="h2">Samenwerking met verwerkers en derde partijen</H>
        <p>
          Wanneer ik bij de verwerking gegevens bekendmaak aan andere partijen en bedrijven (verwerkers of derde partijen), of directe of andere toegang tot de gegevens verleen, gebeurt dit alleen op basis van wettelijke toestemming (bijv. als het overdragen van gegevens aan derden, zoals een betalingsdienstaanbieder, nodig is voor het uitvoeren van een contract in overeenstemming met Artikel 6 (1, b) AVG, u toestemming hebt gegeven, een wettelijke verplichting bestaat, of op basis van onze legitieme belangen (bijv. bij het gebruik van agenten, webhosters, enz.).
        </p>
        <p>
          Wanneer ik derde partijen opdracht geef om gegevens te verwerken op basis van een zogenaamd 'verwerkingsovereenkomst', gebeurt dit op basis van Artikel 28 AVG.
        </p>
        <H as="h2">Overdracht naar derde landen</H>
        <p>
          Wanneer ik gegevens verwerk in een derde land (d.w.z. buiten de Europese Unie (EU) of de Europese Economische Ruimte (EER)), of dit gebeurt als onderdeel van het gebruik van diensten van derden of de openbaarmaking of, zoals van toepassing, overdracht van gegevens aan derde partijen, gebeurt dit alleen indien nodig om onze (pre-)contractuele verplichtingen na te komen, op basis van uw toestemming, vanwege een wettelijke verplichting, of op basis van onze legitieme belangen. Onder voorbehoud van wettelijke of contractuele toestemming verwerk ik gegevens of laat ik gegevens verwerken in een derde land alleen wanneer aan de specifieke vereisten van Artikelen 44 e.v. AVG is voldaan. Met andere woorden, verwerking vindt bijvoorbeeld plaats op basis van specifieke garanties zoals de officieel erkende vaststelling van het gegevensbeschermingsniveau dat overeenkomt met de EU (bijv. voor de VS door het 'Privacy Shield') of naleving van officieel erkende specifieke verplichtingen (zogenaamde 'standaard contractclausules').
        </p>
        <H as="h2">Rechten van betrokkenen</H>
        <p>
          U heeft het recht om bevestiging te eisen of de betreffende gegevens worden verwerkt en om informatie over deze gegevens te ontvangen, evenals verdere informatie en een kopie van de gegevens in overeenstemming met Artikel 15 AVG.
        </p>
        <p>
          In overeenstemming met Artikel 16 AVG, heeft u het recht om te eisen dat gegevens die op u betrekking hebben worden aangevuld, of onjuiste gegevens over u worden gecorrigeerd.
        </p>
        <p>
          In overeenstemming met Artikel 17 AVG, heeft u het recht om te eisen dat gegevens die op u betrekking hebben zonder onnodige vertraging worden gewist of, alternatief in overeenstemming met Artikel 18 AVG, beperkte verwerking van de gegevens.
        </p>
        <p>
          U heeft het recht om de gegevens die u mij heeft verstrekt te ontvangen in overeenstemming met Artikel 20 AVG, en om te verzoeken om de overdracht ervan aan een andere verwerkingsverantwoordelijke.
        </p>
        <p>
          U heeft ook het recht om in overeenstemming met Artikel 77 AVG een klacht in te dienen bij de relevante toezichthoudende autoriteit.
        </p>
        <H as="h2">Herroeping</H>
        <p>U heeft het recht om verleende toestemming in overeenstemming met Artikel 7 (3) AVG met toekomstige werking in te trekken.</p>
        <H as="h2">Recht van bezwaar</H>
        <p>
          In overeenstemming met Artikel 21 AVG, kunt u bezwaar maken tegen de toekomstige verwerking van gegevens die op u betrekking hebben. In het bijzonder kan het bezwaar gericht zijn tegen verwerking voor direct marketing doeleinden.
        </p>
        <H as="h2">Cookies en het recht van bezwaar bij direct marketing</H>
        <p>
          Cookies zijn kleine bestanden die op de computers van gebruikers worden opgeslagen. Verschillende gegevens kunnen binnen de cookies worden opgeslagen. Een cookie dient primair om details over een gebruiker (of, indien van toepassing, het apparaat waarop de cookie is opgeslagen) op te slaan tijdens of zelfs na zijn/haar bezoek binnen een online aanbod. Cookies die worden verwijderd nadat een gebruiker een online aanbod verlaat en zijn/haar browser sluit, worden tijdelijke cookies of, indien van toepassing, sessiecookies genoemd. Bijvoorbeeld, een inlogstatus kan in een dergelijke cookie worden opgeslagen. Cookies die blijven bestaan zelfs nadat de browser is gesloten, worden permanente of persistente cookies genoemd. Dit maakt bijvoorbeeld mogelijk dat de inlogstatus wordt opgeslagen als de gebruiker het na enkele dagen bezoekt. De interesses van gebruikers voor het meten van het publiek of marketingdoeleinden kunnen ook in een dergelijke cookie worden opgeslagen. Cookies die door andere aanbieders dan de verwerkingsverantwoordelijke van het online aanbod worden aangeboden, worden derde partij cookies genoemd (anders zijn het eerste partij cookies als alleen de eigen cookies bestaan).
        </p>
        <p>Ik kan tijdelijke en permanente cookies gebruiken, en verduidelijk dit in ons gegevensbeschermingsbeleid.</p>
        <p>
          Waar gebruikers niet willen dat cookies op hun computer worden opgeslagen, worden zij verzocht om de overeenkomstige optie in de systeeminstellingen uit te schakelen. Opgeslagen cookies kunnen worden verwijderd in de systeeminstellingen van de browser. Het uitsluiten van cookies kan leiden tot beperkte functionaliteit van dit online aanbod.
        </p>
        <p>
          Een algemeen bezwaar tegen het gebruik van cookies voor online marketingdoeleinden kan worden verklaard met een breed scala aan diensten, vooral in het geval van tracking, via de VS-site http://www.aboutads.info/choices/ of de EU-site http://www.youronlinechoices.com/. Bovendien kan de opslag van cookies worden bereikt door ze uit te schakelen in de browserinstellingen. Houd er rekening mee dat niet alle functies van dit online aanbod dan kunnen worden gebruikt.
        </p>
        <p>
          Bij het bezoeken van onze website worden gebruikers geïnformeerd over het gebruik van cookies voor analyse doeleinden door een infobanner die verwijst naar het gegevensbeschermingsbeleid. In dit opzicht is er ook een verwijzing naar hoe de opslag van cookies kan worden voorkomen in de browserinstellingen. Deze zogenaamde cookie tracking filter helpt de gebruiker bij het opslaan van welke cookies moeten worden ingesteld. Zijn/haar eigen cookie wordt voor dit doel opgeslagen. Als u uw cookies verwijdert, dient u deze cookie niet te verwijderen, omdat anders de cookie tracking filter uw voorkeuren niet kan herkennen.
        </p>
        <H as="h2">Gegevens wissen</H>
        <p>
          Door mij verwerkte gegevens worden gewist in overeenstemming met Artikelen 17 en 18 AVG, of beperkt in de verwerking. Tenzij uitdrukkelijk vermeld als onderdeel van het gegevensbeschermingsbeleid, worden bij mij opgeslagen gegevens verwijderd zodra ze niet langer nodig zijn voor het doel, en er geen wettelijke verplichtingen tot bewaring aan de verwijdering in de weg staan. Waar gegevens niet worden verwijderd omdat ze nodig zijn voor andere wettige doeleinden toegestaan door de wetgeving, wordt de verwerking ervan beperkt. Met andere woorden, de gegevens worden geblokkeerd en niet verwerkt voor andere doeleinden. Bijvoorbeeld, dit geldt voor gegevens die moeten worden bewaard volgens handels- of belastingwetgeving.
        </p>
        <H as="h2">Zakelijke verwerking</H>
        <p>Ik verwerk ook</p>
        <p>– contractgegevens (bijv. contractueel object, looptijd, klantcategorie).</p>
        <p>– betalingsgegevens (bijv. bankgegevens, betalingsgeschiedenis)</p>
        <p>
          van mijn klanten, potentiële klanten en zakenpartners voor het doel van het verlenen van contractuele diensten,
          service en klantenservice, marketing, reclame en marktonderzoek.
        </p>
        <H as="h2">Hosting</H>
        <p>
          De hostingdiensten die ik gebruik, dienen om de volgende diensten te bieden: infrastructuur- en platformdiensten,
          rekencapaciteit, opslagruimte en databaseservices, en beveiligingsdiensten evenals technische
          onderhoudsdiensten die ik gebruik voor het doel van het exploiteren van deze online aanbieding.
        </p>
        <p>
          Daarbij verwerk ik of, indien van toepassing, mijn hostingprovider inventarisgegevens, contactgegevens, inhoudsgegevens,
          contractgegevens, gebruiksgegevens, meta- en communicatiegegevens van klanten en potentiële klanten van en bezoekers
          van deze online aanbieding op basis van onze legitieme belangen in het efficiënt en veilig aanbieden van deze online
          aanbieding in overeenstemming met Artikel 6 (1, f) GDPR in combinatie met Artikel 28 GDPR (het aangaan van een
          verwerkersovereenkomst).
        </p>
        <H as="h2">Verzameling van toegangsgegevens en logbestanden</H>
        <p>
          Op basis van mijn legitieme belang in de zin van Artikel 6 (1, f) GDPR, verzamel ik of, indien van toepassing, mijn hostingprovider gegevens over elke toegang tot de server waarop deze dienst is gevestigd (zogenaamde serverlogbestanden). Toegangsgegevens omvatten de naam van de bezochte website, bestand, datum en tijd van toegang, hoeveelheid overgedragen gegevens, rapport van succesvolle toegang, browsertype met versie, besturingssysteem van de gebruiker, referrer-URL (voorheen bezochte site), IP-adres en aanvragende provider.
        </p>
        <p>
          Tijdelijke opslag van het IP-adres door het systeem is noodzakelijk om levering van de website aan de computer van de gebruiker mogelijk te maken. Het IP-adres van de gebruiker moet voor de duur van de sessie opgeslagen blijven om dit mogelijk te maken.
        </p>
        <p>
          Opslag vindt plaats in logbestanden om de functionaliteit van de website te waarborgen, vooral voor het analyseren van een aanval (DOS-aanval) en het misbruik van de geboden tools. De gegevens dienen ook om de website te optimaliseren en de veiligheid van mijn IT-systemen te waarborgen. Gegevens worden in dit opzicht niet geëvalueerd voor marketingdoeleinden.
        </p>
        <p>
          De gegevens worden gewist zodra ze niet langer nodig zijn om het doel van de verzameling te bereiken. Dit is over het algemeen het geval na 30 dagen.
        </p>
        <p>
          De verzameling van gegevens voor het leveren van de website en het opslaan van de gegevens in logbestanden is verplicht voor de werking van de website. Als gevolg hiervan kan de gebruiker geen bezwaar maken.
        </p>
        <H as="h2">Verlenen van contractuele diensten</H>
        <p>
          Ik verwerk inventarisgegevens (bijv. namen en adressen evenals contactgegevens van klanten) en contractgegevens (bijv. gebruikte diensten, namen van contactpersonen, betalingsinformatie) voor het vervullen van mijn contractuele verplichtingen en diensten in overeenstemming met Artikel 6 (1, b) GDPR. In online formulieren gemarkeerde verplichte velden zijn vereist voor het aangaan van het contract.
        </p>
        <p>
          Bij gebruik van mijn online diensten sla ik het IP-adres en de tijd van de respectieve gebruikersactiviteit op. Opslag vindt plaats op basis van mijn legitieme belangen, ook om de gebruiker te beschermen tegen misbruik en ander ongeoorloofd gebruik. Deze gegevens worden in principe niet aan derden verstrekt, tenzij vereist voor het opvolgen van mijn claims of er een wettelijke verplichting in deze zin bestaat in overeenstemming met Artikel 6 (1, c) GDPR.
        </p>
        <p>
          Ik verwerk gebruiksgegevens (bijv. bezochte pagina's van mijn online aanbod, interesse in mijn producten) en inhoudsgegevens (bijv. invoer in het contactformulier of gebruikersprofiel) voor promotionele doeleinden in een gebruikersprofiel om gebruikers bijvoorbeeld productinformatie te tonen op basis van eerder gebruikte diensten.
        </p>
        <p>
          De gegevens worden verwijderd bij het verstrijken van wettelijke garantie- en vergelijkbare verplichtingen, en de noodzaak om de gegevens te bewaren wordt elke drie jaar gecontroleerd. In het geval van wettelijke archiveringsverplichtingen, wordt verwijdering bij het verstrijken uitgevoerd. Gegevens in een klantaccount blijven tot verwijdering daarvan.
        </p>
        <H as="h2">Administratie, boekhouding, kantoororganisatie, contactbeheer</H>
        <p>
          Ik verwerk gegevens als onderdeel van administratieve taken evenals de organisatie van mijn bedrijf, boekhouding en
          naleving van wettelijke verplichtingen zoals archivering. Daarom verwerk ik dezelfde gegevens die ik verwerk bij
          het leveren van mijn contractuele diensten. De basis voor verwerking zijn Artikel 6 (1, c) GDPR en Artikel 6 (1, f)
          GDPR. Klanten, potentiële klanten, zakenpartners en bezoekers van de website worden beïnvloed door
          verwerking. Het doel en het belang van verwerking liggen in de administratie, boekhouding, kantoororganisatie en
          archivering van gegevens, d.w.z. taken die dienen om mijn bedrijfsactiviteiten te onderhouden, mijn taken uit te
          voeren en mijn diensten te verlenen. De verwijdering van gegevens met betrekking tot contractuele diensten en communicatie
          komt overeen met de details vermeld bij deze verwerkingsactiviteiten.
        </p>
        <p>
          Daarom maak ik gegevens bekend of draag ik deze over aan de Belastingdienst, adviseurs zoals belastingadviseurs of accountants, en
          andere inninginstanties en betalingsdienstaanbieders.
        </p>
        <p>
          Bovendien sla ik op basis van mijn commerciële belangen details op over leveranciers, organisatoren en andere
          zakenpartners, bijvoorbeeld voor het doel van latere contactopname. In principe deel ik deze
          gegevens die voornamelijk betrekking hebben op bedrijven op permanente basis.
        </p>
        <H as="h2">Bedrijfsanalyse en marktonderzoek</H>
        <p>
          Om mijn bedrijf commercieel te kunnen opereren en markttrends te identificeren, en klant- en gebruikerswensen
          te begrijpen, analyseer ik de gegevens die beschikbaar zijn over bedrijfsprocessen, contracten, aanvragen, enz. Daarbij
          verwerk ik inventarisgegevens, communicatiegegevens, contractgegevens, betalingsgegevens, gebruiksgegevens en metadata op
          basis van Artikel 6 (1, f) GDPR, waarbij betrokkenen klanten, potentiële klanten, zakenpartners en
          bezoekers en gebruikers van het online aanbod omvatten.
        </p>
        <p>
          Analyse vindt plaats voor het doel van bedrijfsbeoordeling, marketing en marktonderzoek. Dit stelt mij in staat
          de profielen van geregistreerde gebruikers te overwegen met details zoals hun aankoopprocessen. Analyse
          dient om de gebruiksvriendelijkheid te verhogen en mijn aanbod en bedrijfsefficiëntie te optimaliseren. Analyse
          is alleen voor mijn eigen gebruik en wordt niet extern gedeeld, tenzij het gaat om geanonimiseerde analyse met samengevoegde waarden.
        </p>
        <p>
          Waar deze analyse of profielen persoonlijk zijn, worden deze gewist of geanonimiseerd na beëindiging van de
          gebruiker, anders na twee jaar na beëindiging van het contract. Bovendien wordt waar mogelijk een algemene commerciële analyse en
          algemene trendbepaling geproduceerd.
        </p>
        <H as="h2">E-mail aanmeldfunctie</H>
        <p>
          Gebruikers hebben de mogelijkheid om zich met hun e-mail te abonneren op mijn inhoud. Gebruikers worden voorzien van de vereiste
          verplichte gegevens bij registratie. Details ingevoerd bij registratie worden gebruikt om gebruik te maken van het
          aanbod. Gebruikers kunnen e-mails ontvangen met betrekking tot aanbiedingen of wijzigingen in de omvang van het aanbod of technische
          voorwaarden. Als gebruikers hun gebruikersaccount hebben geannuleerd, worden hun gegevens met betrekking tot het gebruikersaccount
          gewist, tenzij bewaring vereist is om redenen op grond van handels- of belastingwetgeving in overeenstemming met
          Artikel 6 (1, c) GDPR. Het is de verantwoordelijkheid van gebruikers om hun gegevens te waarborgen voor het einde van het contract in
          geval van annulering. Ik heb het recht om alle gegevens van de gebruiker die tijdens het contract zijn opgeslagen onherroepelijk te wissen.
        </p>
        <p>
          Wanneer mijn registratie- en inlogfuncties worden gebruikt, evenals wanneer het gebruikersaccount wordt gebruikt, sla ik
          het IP-adres en de tijd van de respectieve gebruikersactiviteit op. Opslag vindt niet alleen plaats op basis van mijn
          legitieme belangen, maar ook op die van de gebruiker ter bescherming tegen misbruik en ander ongeoorloofd gebruik. Deze gegevens
          worden in principe niet aan derden verstrekt tenzij vereist om mijn claims op te volgen of er een wettelijke
          verplichting in deze zin bestaat in overeenstemming met Artikel 6 (1, c) GDPR.
        </p>
        <H as="h2">Contact</H>
        <p>
          Wanneer je contact met mij opneemt (bijvoorbeeld via het contactformulier, e-mail, telefoon of via sociale media), worden
          de gegevens van de gebruiker verwerkt om de contactaanvraag af te handelen in overeenstemming met Artikel 6 (1, b) GDPR.
          De gegevens van de gebruiker kunnen worden opgeslagen in een customer relationship management systeem (CRM-systeem) of een vergelijkbaar
          aanvraagbeheersysteem.
        </p>
        <p>
          Ik verwijder de aanvragen zodra ze niet langer nodig zijn. Ik beoordeel de noodzaak elke twee jaar, en
          wettelijke archiveringsverplichtingen zijn ook van toepassing.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;
